// import showdown from "showdown"

// showdown.extension("paragraph", () => [{
//   type: "listener",
//   listeners: {
//     "hashHTMLBlocks.after": function(event, text, converter, options, globals) {
//       text = text.replace(/^ {0,3}<[a-z]+\b[^>]*>$/gmi, (wm) => {
//         return "\n\n¨K" + (globals.gHtmlBlocks.push(wm) - 1) + "K\n\n"
//       })
//       return text
//     }
//   }
// }])

// const converter = new showdown.Converter({ extensions: [ "paragraph" ] })

// export default (content: string) => converter.makeHtml(content)

import marked from "marked"
export default marked
