const easings = {
  linear(t: number) {
    return t
  },
  easeInQuad(t: number) {
    return t * t
  },
  easeOutQuad(t: number) {
    return t * (2 - t)
  },
  easeInOutQuad(t: number) {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t
  },
  easeInCubic(t: number) {
    return t * t * t
  },
  easeOutCubic(t: number) {
    return (--t) * t * t + 1
  },
  easeInOutCubic(t: number) {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
  },
  easeInQuart(t: number) {
    return t * t * t * t
  },
  easeOutQuart(t: number) {
    return 1 - (--t) * t * t * t
  },
  easeInOutQuart(t: number) {
    return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t
  },
  easeInQuint(t: number) {
    return t * t * t * t * t
  },
  easeOutQuint(t: number) {
    return 1 + (--t) * t * t * t * t
  },
  easeInOutQuint(t: number) {
    return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t
  }
}

interface ConfigProps {
  destination?: HTMLElement | number
  duration?: number
  easing?: string
  callback?: Function
  container?: HTMLElement | Window
}

export default (config?: ConfigProps) => {
  const startTime = "now" in window.performance
    ? performance.now() : new Date().getTime()

  // const documentHeight = Math.max(
  //   document.body.scrollHeight,
  //   document.body.offsetHeight,
  //   document.documentElement.clientHeight,
  //   document.documentElement.scrollHeight,
  //   document.documentElement.offsetHeight
  // )

  const windowHeight = window.innerHeight ||
    document.documentElement.clientHeight ||
    document.getElementsByTagName("body")[0].clientHeight

  const c = {
    destination: windowHeight,
    duration: 400,
    easing: "linear",
    callback: null,
    container: window,
    ...config
  }

  const getCurrentScrollPosition = () => {
    if(c.container instanceof HTMLElement) {
      return c.container.scrollTop
    }
    return c.container.pageYOffset
  }

  const setScroll = (scrollTo: number) => {
    if(c.container instanceof HTMLElement) {
      c.container.scrollTop = scrollTo
    } else {
      c.container.scroll(0, scrollTo)
    }
  }

  let start = getCurrentScrollPosition()

  const end = c.destination instanceof HTMLElement
    ? c.destination.offsetTop : c.destination

  if("requestAnimationFrame" in window === false) {
    setScroll(end)
    if(c.callback) c.callback()
    return
  }

  const scroll = () => {
    const now = "now" in window.performance
      ? performance.now() : new Date().getTime()
    const elapsed = now - startTime
    const time = Math.min(1, (elapsed / c.duration))
    const timeFunction = easings[c.easing](time)
    const scrollTo = Math.ceil((timeFunction * (end - start)) + start)

    setScroll(scrollTo)

    if(getCurrentScrollPosition() >= end || elapsed > c.duration) {
      if(c.callback) c.callback()
      return
    }

    requestAnimationFrame(scroll)
  }

  scroll()
}
