import React, { useState } from "react"
import Swiper from "react-id-swiper"
import "swiper/css/swiper.min.css"

interface SliderProps {
  options?: Object
  children: JSX.Element[]
  className?: string
  showNav?: boolean
  centerNav?: boolean
}

interface NavButtonProps {
  className: string
  children: React.ReactNode
  onClick: () => void
}

const Slider: React.FC<SliderProps> = ({
  options, children, className, showNav, centerNav
}) => {
  const [ swiper, setSwiper ] = useState(null)

  const next = () => {
    if(swiper !== null) swiper.slideNext()
  }
  const prev = () => {
    if(swiper !== null) swiper.slidePrev()
  }

  const NavButton: React.FC<NavButtonProps> = ({ children, onClick, className }) => {
    return (
      <button
        onClick={onClick}
        className={`px-1 flex items-center justify-center w-8 h-10 absolute opacity-75 font-serif text-5xl font-light z-10 leading-none bg-black text-white hover:opacity-100 ${className ? className : ""}`}
        style={{
          top: centerNav ? "50%" : 0,
          transform: centerNav ? "translateY(-50%)" : ""
        }}
      >
        {children}
      </button>
    )
  }

  return (
    <div className={`relative w-full ${className ? className : ''}`}>
      <Swiper
        getSwiper={setSwiper}
        {...options}
      >
        {React.Children.map(children, (slide) => (
          React.cloneElement(slide as JSX.Element)
        ))}
      </Swiper>

      {showNav && (
        <>
          <NavButton
            onClick={prev}
            className="left-0"
          >
            <svg
              viewBox="0 0 24 24"
              className="w-full"
            >
              <path
                fill="currentColor"
                d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64z"
              />
            </svg>
          </NavButton>

          <NavButton
            onClick={next}
            className="right-0"
          >
            <svg
              viewBox="0 0 24 24"
              className="w-full"
            >
              <path
                fill="currentColor"
                d="M10 19a1 1 0 0 1-.64-.23 1 1 0 0 1-.13-1.41L13.71 12 9.39 6.63a1 1 0 0 1 .15-1.41 1 1 0 0 1 1.46.15l4.83 6a1 1 0 0 1 0 1.27l-5 6A1 1 0 0 1 10 19z"
              />
            </svg>
          </NavButton>
        </>
      )}
    </div>
  )
}

Slider.defaultProps = {
  showNav: true,
  centerNav: true
}

export default Slider
