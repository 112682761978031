import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Image, { ImageProps } from "../components/Image"
import Slider from "../components/Slider"
import convert from "../utils/convert"
import styled from "styled-components"
import Fade from "react-reveal/Fade"
import Zoom from "react-reveal/Zoom"
import autoscroll from "../utils/autoscroll"

interface IndexPageTemplateProps {
  start: {
    title: string
    button: string
    text: string
    videoSrc: string
  }
  logos: {
    src: ImageProps
    alt: string
    href: string
  }[]
  pledge: {
    title: string
    text: string
    list: {
      title: string
      text: string
      icon: ImageProps
    }[]
  }
  partners: {
    title: string
    text: string
    list: {
      title: string
      text: string
      image: ImageProps
      href: string
    }[]
  }
}

const VideoOuterWrapper = styled.div`
  min-height: 300px;
  background-image: url("https://assets.bonum.no/bilder/miljo/video-placeholder2.jpg");
  &:before {
    z-index: 1;
    content: "";
    position: absolute;
    height: 11.5vw;
    width: 100vw;
    background-color: #fff;
    bottom: -6vw;
    transform: skew(0deg, -6deg);
  }

  @media (min-aspect-ratio: 16/10) {
    & {
      min-height: 100vh;
    }
  }
`

const VideoInnerWrapper = styled.div`
  box-sizing: border-box;
  height: 56.25vw;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  width: 177.77777778vh;
`

const IngressWrapper = styled.section`
  margin-top: -11.5vw;
`

const IndexPageTemplate: React.FC<IndexPageTemplateProps> = ({
  start, logos, pledge, partners
}) => {
  const [ showVideo, setShowVideo ] = useState(false)

  useEffect(() => {
    const onMatch = (e: MediaQueryList | MediaQueryListEvent) => {
      setShowVideo(e.matches)
    }
    const mql = window.matchMedia("(min-width: 1280px)")
    mql.addListener(onMatch)
    onMatch(mql)
    return () => { mql.removeListener(onMatch) }
  }, [])

  return (
    <main
      id="index-page"
      className="overflow-x-hidden"
    >
      <section
        id="start"
        className="flex flex-col min-h-screen sm:min-h-0"
      >
        <VideoOuterWrapper className="relative h-full w-full flex-1 overflow-hidden bg-cover bg-center">
          <div className="absolute top-0 right-0 pt-8 pr-12 z-10 sm:pt-4 sm:pr-5">
            <a
              href="https://bonum.no"
              title="Gå til bonum"
            >
              <img
                className="sm:h-6"
                src="/img/bonum-logo.png"
                alt="Bonum logo"
              />
            </a>
          </div>
  
          <VideoInnerWrapper>
            <div className="absolute inset-0">
              {showVideo ? (
                <video
                  id="index-page_start_video"
                  className="w-full h-full"
                  // preload="none"
                  playsInline={true}
                  autoPlay={true}
                  loop={true}
                  muted={true}
                >
                  <source
                    type="video/mp4"
                    src="https://assets.bonum.no/bilder/miljo/bg.mp4"
                  />
                </video>
              ): (
                <iframe
                  id="video"
                  className="w-full h-full"
                  src={start.videoSrc}
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                />
              )}
            </div>
          </VideoInnerWrapper>
        </VideoOuterWrapper>

        <IngressWrapper className="px-12 relative z-10 md:px-4 2xs:px-3">
          <div className="text-center mx-auto w-full max-w-4xl rounded-lg bg-white py-12 px-12 xl:-mt-6 xl:pt-12 xl:px-8 md:py-8 sm:px-4 sm:py-6">
            <h1
              className="title leading-none xl:text-6xl lg:text-5xl sm:text-4xl sm:font-bold xs:text-3xl whitespace-no-wrap"
              dangerouslySetInnerHTML={{
                __html: convert(start.title)
              }}
            />
    
            <div
              className="my-8 markdown text-lg lg:my-6 lg:text-inherit 2xs:-mx-5"
              dangerouslySetInnerHTML={{
                __html: convert(start.text)
              }}
            />
    
            <button
              className="px-6 py-3 uppercase font-semibold text-sm tracking-wide bg-accent text-white hover:bg-white hover:text-accent shadow-lg rounded"
              onClick={() => { autoscroll() }}
            >
              {start.button}
            </button>
          </div>
        </IngressWrapper>
      </section>

      <section
        id="logos"
        className="px-8 lg:px-4 py-12 xl:pb-0 xl:pt-1 relative"
      >
        <div className="mb-12 mx-auto flex items-center justify-around max-w-5xl flex-wrap">
          {logos.map((logo: any, index: number) => (
            <a
              key={index}
              href={logo.href}
              target="_blank"
              rel="nofollow"
              className="mt-12 block sm:w-1/2 hover:opacity-50"
            >
              <img
                src={logo.src.childImageSharp.fluid.src}
                alt={logo.alt}
                className="w-24 mx-auto"
              />
            </a>
          ))}
        </div>
      </section>
  
      <section
        id="pledge"
        className="px-8 lg:px-4 py-32 lg:py-8 relative"
        style={{ backgroundImage: "linear-gradient(30deg, #f7fdf9, #f1f6f9, #f7fdf9)" }}
      >
        <img
          src="/img/leafs-1.png"
          className="absolute right-0 top-0 max-w-xs lg:hidden"
          style={{ zIndex: 0, transform: "translateY(-20%)" }}
        />
  
        <img
          src="/img/leafs-2.png"
          className="absolute left-0 bottom-0 max-w-sm lg:hidden"
          style={{ zIndex: 0, transform: "translateY(20%)" }}
        />
  
        <div className="max-w-5xl mx-auto mb-32 lg:mb-16 lg:max-w-full lg:text-center">
          <Fade>
            <h2 className="subtitle">
              {pledge.title}
            </h2>
  
            <hr className="sep-vert my-8 lg:mx-auto" />
  
            <div
              className="max-w-2xl markdown lg:mx-auto"
              dangerouslySetInnerHTML={{
                __html: convert(pledge.text)
              }}
            />
          </Fade>
        </div>
  
        <div className="mx-auto w-full max-w-8xl flex flex-wrap justify-center relative">
          {pledge.list.map(({ title, text, icon }, index) => (
            <div
              key={index}
              className="w-1/3 px-8 py-16 lg:px-4 lg:py-8 md:w-1/2 sm:w-full sm:px-0"
            >
              <Zoom>
                <img
                  className="w-16 mr-4"
                  src={icon.childImageSharp.fluid.src}
                  alt="Icon"
                />
  
                <div className="flex items-center justify-start">
                  <div className="">
                    <h3 className="text-2xl font-bold my-6 md:text-xl">
                      {index + 1}. {title}
                    </h3>
  
                    <div
                      className="markdown"
                      dangerouslySetInnerHTML={{
                        __html: convert(text)
                      }}
                    />
                  </div>
                </div>
              </Zoom>
            </div>
          ))}
        </div>
      </section>
  
      <section
        id="partners"
        className="px-8 lg:px-4 py-32 sm:py-8"
      >
        <div className="max-w-5xl mb-32 lg:mb-16 mx-auto flex justify-end text-right lg:justify-center lg:max-w-full lg:text-center">
          <div>
            <Fade>
              <h2 className="subtitle">
                {partners.title}
              </h2>
  
              <hr className="sep-vert my-8 ml-auto lg:mx-auto" />
  
              <div
                className="max-w-2xl markdown lg:mx-auto"
                dangerouslySetInnerHTML={{
                  __html: convert(partners.text)
                }}
              />
            </Fade>
          </div>
        </div>
  
        <Fade right>
          <div className="mx-auto w-full max-w-10xl flex items-start">
            <Slider
              centerNav={false}
              options={{
                slidesPerView: 1.2,
                spaceBetween: 16,
                breakpoints: {
                  640: {
                    slidesPerView: 2.2,
                    spaceBetween: 16
                  },
                  1280: {
                    slidesPerView: 3.2,
                    spaceBetween: 32
                  },
                  1600: {
                    slidesPerView: 4,
                    spaceBetween: 64
                  }
                }
              }}
            >
              {partners.list.map(({ title, text, image, href }, index) => (
                <div key={index}>
                  <Image
                    className="w-full object-cover object-center rounded-lg shadow-xl"
                    style={{ height: 320 }}
                    src={image.src}
                    alt={image.alt}
                  />
  
                  <div className="mt-6 mb-4 flex items-center justify-between xl:flex-col xl:items-start">
                    <h2
                      className="text-2xl font-bold md:text-xl markdown"
                      dangerouslySetInnerHTML={{
                        __html: convert(title)
                      }}
                    />
  
                    <a
                      className="inline-flex items-center uppercase font-semibold text-xs tracking-wide text-accent hover:text-black xl:mt-2"
                      href={href}
                      target="_blank"
                      rel="nofollow"
                    >
                      Se nettside <span className="ml-3 -mr-1 -mt-1 font-medium leading-none text-lg">›</span>
                    </a>
                  </div>
  
                  <div
                    className="markdown xs:text-base"
                    dangerouslySetInnerHTML={{
                      __html: convert(text)
                    }}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </Fade>
      </section>
    </main>
  )
}

interface IndexPageProps {
  data: {
    markdownRemark: {
      frontmatter: IndexPageTemplateProps
    }
  }
}

const IndexPage: React.FC<IndexPageProps> = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <IndexPageTemplate
      start={frontmatter.start}
      logos={frontmatter.logos}
      pledge={frontmatter.pledge}
      partners={frontmatter.partners}
    />
  )
}

export default IndexPage

export const indexPageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {

        start {
          title
          text
          button
          videoSrc
        }

        logos {
          src {
            childImageSharp {
              fluid(maxHeight: 128 quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
          href
        }

        pledge {
          title
          text
          list {
            title
            text
            icon {
              childImageSharp {
                fluid(maxWidth: 1920 quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }

        partners {
          title
          text
          list {
            image {
              src {
                childImageSharp {
                  fluid(maxWidth: 1920 quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
            title
            text
            href
          }
        }

      }
    }
  }
`
